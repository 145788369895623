import { CheckCircle, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks/hooks";
import { getTimeLine } from "../../Redux/Slice/Tickets/TicketsSlics";

const TimeLine = () => {
  const dispatch = useAppDispatch();
  const { timeline } = useAppSelector((state) => state.tickets);

  const TimeLineData = timeline?.data;
  const SideSuevay = TimeLineData?.site_survey;
  const Design = TimeLineData?.design;
  const PermitSubmittal = TimeLineData?.permit_submittal;
  const Monitor = TimeLineData?.monitor;
  const FinalInspection = TimeLineData?.final_inspection;
  const ProductPurchase = TimeLineData?.product_purchase;
  const Installation = TimeLineData?.installation;

  const PTO = TimeLineData?.pto;

  useEffect(() => {
    dispatch(getTimeLine());
  }, []);
  return (
    <>
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Site survey{" "}
        </Typography>
        {SideSuevay ? (
          <>
            <Box
              sx={{
                display: "flex",
                py: 1,
                px: 2,
                justifyContent: "space-between",
                background: "#e3e1e1",
                borderRadius: " 0 0 .5rem .5rem  ",
              }}
            >
              {SideSuevay?.name ? (
                <Typography variant="subtitle1" component="h5">
                  {SideSuevay?.name}
                </Typography>
              ) : null}
              {SideSuevay?.survey_date ? (
                <Typography variant="subtitle1" component="h5">
                  {SideSuevay?.survey_date}
                </Typography>
              ) : null}
              {SideSuevay?.time ? (
                <Typography variant="subtitle1" component="h5">
                  {SideSuevay?.time}
                </Typography>
              ) : null}
            </Box>
            <Typography
              sx={{
                py: 2,
                width: {
                  lg: "60%",
                  md: "80%",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#777",
                },
              }}
            >
              Notes: <br />
              Your Project Manager will confirm this time works from our end. We
              will message you a confirmation or new requested time.
            </Typography>
            <Box py={2}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                1. Do you have any animals outside the property?
              </Typography>
              <Box>
                {Boolean(SideSuevay?.any_dogs) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f96161",
                      ".MuiSvgIcon-root": {
                        color: "#ffb2b2",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box py={2}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                2. Would you prefer to be on site during survey?
              </Typography>
              <Box>
                {Boolean(SideSuevay?.be_on_site) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f96161",
                      ".MuiSvgIcon-root": {
                        color: "#ffb2b2",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                3. Confirm survey time works?
              </Typography>
              <Box>
                {Boolean(SideSuevay?.confirm_survey_time) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f96161",
                      ".MuiSvgIcon-root": {
                        color: "#ffb2b2",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </>
        ) : (
          "There is no Side Survey"
        )}
      </Box>

      {/* design */}

      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Design
        </Typography>

        <Box
          sx={{
            display: "flex",
            py: 1,
            px: 2,
            justifyContent: "space-between",
            background: "#e3e1e1",
            borderRadius: " 0 0 .5rem .5rem  ",
          }}
        >
          {Design ? (
            <>
              <Typography
                variant="subtitle1"
                component="h5"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ fontWeight: "900", marginRight: 10 }}>
                  plan set
                </span>
                {Design?.plan_set ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Approve"
                    sx={{
                      ml: 2,
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="Deny"
                    sx={{
                      ml: 2,
                      color: "#fff",
                      background: "#666666",
                      ".MuiSvgIcon-root": {
                        color: "#949494",
                      },
                    }}
                  />
                )}
              </Typography>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="subtitle1"
                component="h5"
              >
                <span style={{ fontWeight: "900", marginRight: 10 }}>
                  Structual calculations{" "}
                </span>

                {Design?.structure_calculation ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Approve"
                    sx={{
                      ml: 2,
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="Deny"
                    sx={{
                      ml: 2,
                      color: "#fff",
                      background: "#666666",
                      ".MuiSvgIcon-root": {
                        color: "#949494",
                      },
                    }}
                  />
                )}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 10 }}>
                  Layout approval
                </span>
                {Design?.approval ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Approve"
                    sx={{
                      ml: 2,
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="Deny"
                    sx={{
                      ml: 2,
                      color: "#fff",
                      background: "#8c8c8c",
                      ".MuiSvgIcon-root": {
                        color: "#dedede",
                      },
                    }}
                  />
                )}{" "}
              </Typography>
            </>
          ) : (
            <Typography
              variant="body1"
              color="#666"
              alignSelf={"center"}
              component="h5"
            >
              There is No Design Yet
            </Typography>
          )}
        </Box>
      </Box>

      {/* Permit submittal */}
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Permit submittal
        </Typography>
        <Box
          sx={{
            display: "flex",
            py: 1,
            px: 2,
            justifyContent: "space-between",
            background: "#e3e1e1",
            borderRadius: " 0 0 .5rem .5rem  ",
          }}
        >
          {PermitSubmittal ? (
            <>
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 1 }}>From </span>
                {new Date(PermitSubmittal?.event_from).toDateString()}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 1 }}>To </span>{" "}
                {new Date(PermitSubmittal?.event_to).toDateString()}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 1 }}>
                  Status{" "}
                </span>
                {PermitSubmittal?.status}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 1 }}>
                  city/county office{" "}
                </span>
                {PermitSubmittal?.county}
              </Typography>
            </>
          ) : (
            <Typography
              variant="body1"
              color="#666"
              alignSelf={"center"}
              component="h6"
            >
              There is No Permit submittal Yet
            </Typography>
          )}
        </Box>
      </Box>

      {/* Product purchase */}
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Product purchase
        </Typography>
        <Box
          sx={{
            display: "flex",
            py: 1,
            px: 2,
            justifyContent: "space-between",
            background: "#e3e1e1",
            borderRadius: " 0 0 .5rem .5rem  ",
          }}
        >
          {ProductPurchase ? (
            <>
              {" "}
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 1 }}>
                  Landing Date{" "}
                </span>{" "}
                {ProductPurchase?.event}
              </Typography>
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 10 }}>
                  Estimated Time
                </span>
                {ProductPurchase?.estimated_time}
              </Typography>
            </>
          ) : (
            <Typography
              variant="body1"
              color="#666"
              alignSelf={"center"}
              component="h6"
            >
              There is No Product purchase Yet
            </Typography>
          )}
        </Box>

        {ProductPurchase && (
          <>
            {" "}
            <Box py={1} mt={3}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                1. Do we have access to your driveway?
              </Typography>
              <Box>
                {Boolean(ProductPurchase?.access_to_driveway) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f50f0f",
                      ".MuiSvgIcon-root": {
                        color: "#aaaaaa",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box py={1}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                2. Will you be at home to sign off for the product from our
                delivery driver?
              </Typography>
              <Box>
                {" "}
                {Boolean(ProductPurchase?.will_be_at_home) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f96161",
                      ".MuiSvgIcon-root": {
                        color: "#ffb2b2",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box py={1}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                3. Is it ok with you to leave the product
              </Typography>
              <Box>
                {" "}
                {Boolean(ProductPurchase?.leave_the_product) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f50f0f",
                      ".MuiSvgIcon-root": {
                        color: "#aaaaaa",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/* Installation */}
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Installation
        </Typography>
        {Installation ? (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                py: 1,
                px: 2,
                justifyContent: "space-between",
                background: "#e3e1e1",
                borderRadius: " 0 0 .5rem .5rem  ",
              }}
            >
              <Typography variant="subtitle1" component="h5">
                {Installation?.date} / {Installation?.time}
              </Typography>
              {/* <Typography variant="subtitle1" component="h5">
                installation pictures
              </Typography> */}
            </Box>
            <Box py={1} mt={3}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                1. Will you be home during installation?
              </Typography>
              <Box>{Installation?.customer_comments}</Box>
            </Box>
            <Box py={2}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                2. Do you have any animals outside the property?
              </Typography>
              <Box>
                {Boolean(Installation?.animals_outside_property) ? (
                  <Chip
                    icon={<CheckCircle sx={{ color: "#fff", ml: 1 }} />}
                    label="Yes"
                    sx={{
                      color: "#fff",
                      background: "#0ad17b",
                      ".MuiSvgIcon-root": {
                        color: "#04a861",
                      },
                    }}
                  />
                ) : (
                  <Chip
                    icon={<HighlightOff />}
                    label="No"
                    sx={{
                      color: "#fff",
                      background: "#f96161",
                      ".MuiSvgIcon-root": {
                        color: "#ffb2b2",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight={700}>
                Media
              </Typography>
              <Grid container spacing={2}>
                {Installation?.meida?.map((item: any) => (
                  <Grid key={item.id} item sm={3} xs={12}>
                    <a href={item?.path} target="_blank">
                      <img
                        src={item?.path}
                        alt={item?.type}
                        style={{
                          width: "100%",
                          borderRadius: "1rem",
                          margin: "1rem 0",
                        }}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* <Box
              sx={{
                fontSize: "1.1rem",
                fontWeight: "600",
                color: "#6c6c6c",
                my: 3,
              }}
            >
              It isn’t necessary as our team is reliable, accountable and
              experienced. If you have any notes you’d like to share with our
              install team regarding your install please notate here
              <Box sx={{ display: "flex", py: 2 }}>
                <Box sx={{ flex: "3" }}>
                  <Input fullWidth placeholder="Add Your Notes.." />
                </Box>
                <Button variant="outlined" sx={{ mx: 2 }}>
                  Add Note
                </Button>
              </Box>
            </Box> */}
          </>
        ) : (
          <Typography
            variant="body1"
            color="#666"
            alignSelf={"center"}
            component="h6"
            sx={{
              background: "#e3e1e1",
              padding: ".5rem 1rem",
              borderRadius: "0 0 .5rem .5rem",
            }}
          >
            There is No Product purchase Yet
          </Typography>
        )}
      </Box>

      {/* Final inspection */}
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Final inspection
        </Typography>
        {FinalInspection ? (
          <>
            <Box
              sx={{
                display: "flex",
                py: 1,
                px: 2,
                justifyContent: "space-between",
                background: "#e3e1e1",
                borderRadius: " 0 0 .5rem .5rem  ",
              }}
            >
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 15 }}>
                  date / time frame
                </span>
                {new Date(FinalInspection?.date_time).toDateString()}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight={700} mt={5}>
                Final Job Card Image
              </Typography>
              <Grid container spacing={2}>
                {FinalInspection?.final_job_card_image?.map((item: any) => (
                  <Grid key={item.id} item sm={3} xs={12}>
                    <a href={item?.path} target="_blank">
                      <img
                        src={item?.path}
                        alt={item?.type}
                        style={{
                          width: "100%",
                          borderRadius: "1rem",
                          margin: "1rem 0",
                        }}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <Typography
            variant="body1"
            color="#666"
            alignSelf={"center"}
            component="h6"
            sx={{
              background: "#e3e1e1",
              padding: ".5rem 1rem",
              borderRadius: "0 0 .5rem .5rem",
            }}
          >
            There is No Final inspection Yet
          </Typography>
        )}
      </Box>

      {/* PTO */}
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          PTO
        </Typography>
        {PTO ? (
          <>
            <Box
              sx={{
                display: "flex",
                py: 1,
                px: 2,
                justifyContent: "space-between",
                background: "#e3e1e1",
                borderRadius: " 0 0 .5rem .5rem  ",
              }}
            >
              <Typography variant="subtitle1" component="h5">
                <span style={{ fontWeight: "900", marginRight: 15 }}>
                  date of approval{" "}
                </span>
                {PTO?.date_of_approval}
              </Typography>
              {/* <Typography variant="subtitle1" component="h5">
                Interconnection approval letter
              </Typography> */}
            </Box>
            <Box>
              <Typography variant="h6" fontWeight={700} mt={5}>
                Media
              </Typography>
              <Grid container spacing={2}>
                {PTO?.media?.map((item: any) => (
                  <Grid key={item.id} item sm={3} xs={12}>
                    <a href={item?.path} target="_blank">
                      <img
                        src={item?.path}
                        alt={item?.type}
                        style={{
                          width: "100%",
                          borderRadius: "1rem",
                          margin: "1rem 0",
                        }}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>{" "}
          </>
        ) : (
          <Typography
            variant="body1"
            color="#666"
            alignSelf={"center"}
            component="h6"
            sx={{
              background: "#e3e1e1",
              padding: ".5rem 1rem",
              borderRadius: "0 0 .5rem .5rem",
            }}
          >
            There is No PTO Yet
          </Typography>
        )}
      </Box>

      {/* Monitor your system */}
      <Box
        sx={{
          my: 3,
          position: "relative",
          "&::after  ": {
            content: `""`,
            position: "absolute",
            top: "0",
            left: "-50px",
            width: "35px",
            height: "35px",
            background: "#2c6fda",
            borderRadius: "50%",
            padding: "1rem",
            border: "4px solid #77a6f39f",
          },
        }}
      >
        <Typography
          variant="h5"
          component="h3"
          sx={{
            background: "#e5e5e5",
            py: 1,
            px: 2,
            borderRadius: ".5rem .5rem 0 0 ",
            fontWeight: "600",
          }}
        >
          Monitor your system
        </Typography>
        {Monitor ? (
          <>
            <Box
              sx={{
                display: "flex",
                py: 1,
                px: 2,
                justifyContent: "space-between",
                background: "#e3e1e1",
                borderRadius: " 0 0 .5rem .5rem  ",
              }}
            >
              <Typography variant="subtitle1" component="h5">
                Instructions How to set up on your phone or computer.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight={700} mt={5}>
                Media
              </Typography>
              <Grid container spacing={2}>
                {Monitor?.meida?.map((item: any) => (
                  <Grid key={item.id} item sm={3} xs={12}>
                    <a href={item?.path} target="_blank">
                      <img
                        src={item?.path}
                        alt={item?.type}
                        style={{
                          width: "100%",
                          borderRadius: "1rem",
                          margin: "1rem 0",
                        }}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>{" "}
          </>
        ) : (
          <Typography
            variant="body1"
            color="#666"
            alignSelf={"center"}
            component="h6"
            sx={{
              background: "#e3e1e1",
              padding: ".5rem 1rem",
              borderRadius: "0 0 .5rem .5rem",
            }}
          >
            There is No Monitor Yet
          </Typography>
        )}
      </Box>
    </>
  );
};

export default TimeLine;
